import { render, staticRenderFns } from "./FacultyEditor.vue?vue&type=template&id=d6835cec&"
import script from "./FacultyEditor.vue?vue&type=script&lang=js&"
export * from "./FacultyEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VContainer,VDataTable,VDialog,VRow,VSnackbar,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d6835cec')) {
      api.createRecord('d6835cec', component.options)
    } else {
      api.reload('d6835cec', component.options)
    }
    module.hot.accept("./FacultyEditor.vue?vue&type=template&id=d6835cec&", function () {
      api.rerender('d6835cec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/faculty/editor/FacultyEditor.vue"
export default component.exports