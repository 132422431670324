<template>
  <v-data-table
    v-bind:headers="headers"
    v-bind:items="tableData"
    v-bind:search="search"
    hide-default-footer
    no-data-text="Não há dados disponíveis para a consulta"
  >
    <template v-slot:item="row">
      <tr v-on:click="clickOnItem(row.item)">
        <td class="text-xs-left">{{ row.item.title }}</td>
        <td class="text-xs-left">{{ row.item.code }}</td>
        <td class="text-xs-left">{{ row.item.type }}</td>
        <td class="text-xs-left">{{ row.item.state }}</td>
        <td class="text-xs-left">{{ row.item.comment }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "technical_report_table",
  props: ["tableData"],
  data() {
    return {
      headers: [
        { sortable: false, text: "Titulo", value: "title", align: "left" },
        { sortable: false, text: "Codigo", value: "code", align: "left" },
        { sortable: false, text: "Tipo", value: "type", align: "left" },
        { sortable: false, text: "Estado", value: "state", align: "left" },
        { sortable: false, text: "Comentario", value: "comment", align: "left" }
      ],
    };
  },
  created() {},
  methods: {
    clickOnItem(item) {
      this.$emit("whenClickOnItem", item);
    },
  },
};
</script>