var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h3", [_vm._v("Relatórios Técnicos " + _vm._s(_vm.test))]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("technical-report-table", {
                attrs: { tableData: _vm.tableData },
                on: { whenClickOnItem: _vm.whenClickOnTableItem }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("technical-report-dialog-get", {
                attrs: {
                  document: _vm.dialogGet.document,
                  show: _vm.dialogGet.show,
                  mode: "EDITOR"
                },
                on: {
                  whenEmitCancel: function($event) {
                    _vm.dialogGet.show = false
                  },
                  whenEmitObtainPDF: _vm.whenClickOnObtainPDF,
                  whenEmitEvaluation: _vm.whenClickOnAcceptOrReject
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  "hide-default-footer": "",
                  "no-data-text": "Não há dados disponíveis para a consulta"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(row) {
                      return [
                        _c(
                          "tr",
                          {
                            on: {
                              click: function($event) {
                                return _vm.selectItem(row.item)
                              }
                            }
                          },
                          [
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(row.item.title))
                            ]),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(row.item.code))
                            ]),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(row.item.type))
                            ]),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(row.item.state))
                            ]),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(row.item.comment))
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.dialog.isShowing,
                callback: function($$v) {
                  _vm.$set(_vm.dialog, "isShowing", $$v)
                },
                expression: "dialog.isShowing"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.dialog.title))
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-row", [
                            _vm._v(" " + _vm._s(_vm.dialog.message))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.acceptDialog }
                        },
                        [_vm._v(" OK ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.cancelDialog }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: 5000 },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { color: "pink", text: "" },
                            on: {
                              click: function($event) {
                                _vm.snackbar.isShowing = false
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" Close ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.snackbar.isShowing,
                callback: function($$v) {
                  _vm.$set(_vm.snackbar, "isShowing", $$v)
                },
                expression: "snackbar.isShowing"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.snackbar.text) +
                  " " +
                  _vm._s(_vm.snackbarCode) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _vm.reportDetail
            ? _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "600px" },
                  model: {
                    value: _vm.reportDetail.isShowing,
                    callback: function($$v) {
                      _vm.$set(_vm.reportDetail, "isShowing", $$v)
                    },
                    expression: "reportDetail.isShowing"
                  }
                },
                [
                  _c("document-detail-dialog", {
                    attrs: { object: _vm.reportDetail.object },
                    on: {
                      whenEmitOk: function($event) {
                        _vm.reportDetail.isShowing = false
                      },
                      whenEmitAction: _vm.executeAction
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }