<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ document.code }} - {{ document.title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <file-download
                :ref="'downloadFile'"
                :file="file"
                style="display: none"
              >
              </file-download>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              {{ document.title }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              {{ document.author }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              {{ document.abstract }}
            </v-col>
            <input
              type="file"
              :ref="'inputFile'"
              style="display: none"
              @change="finishUpdateFile"
            />
          </v-row>
          <v-row>
            <v-col cols="12">
              <vue-mathjax :formula="document.abstract"></vue-mathjax>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          v-if="document.link != '-'"
          @click="obtainPDF"
        >
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
            >picture_as_pdf</i
          >
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
          >
            file_download
          </i>
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          v-if="
            mode === 'STUDENT' &&
            (document.state === 'Registrado' ||
              document.state === 'Submetido' ||
              document.state === 'Rejeitado')
          "
          @click="startUpdateFile"
        >
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
          >
            picture_as_pdf
          </i>
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
          >
            file_upload
          </i>
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          v-if="mode === 'EDITOR'"
          @click="startEvaluation('ACCEPT')"
        >
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
            >thumb_up</i
          >
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          v-if="mode === 'EDITOR'"
          @click="startEvaluation('REJECT')"
        >
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
            >thumb_down</i
          >
        </v-btn>
        <v-btn color="blue darken-1" text @click="emitCancel">
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
            >close</i
          >
        </v-btn>
      </v-card-actions>
    </v-card>
    <custom-confirm-dialog
      :show="confirmDialog.show"
      :message="confirmDialog.message"
      :title="confirmDialog.title"
      :code="confirmDialog.code"
      @whenEmitAccept="executeConfirm"
      @whenEmitCancel="confirmDialog.show = false"
    ></custom-confirm-dialog>
  </v-dialog>
</template>
<script>
import { VueMathjax } from "vue-mathjax";
import FileDownload from "../../common/file/FileDownload.vue";
import CustomConfirmDialog from "./CustomConfirmDialog.vue";
export default {
  components: { "vue-mathjax": VueMathjax, CustomConfirmDialog, FileDownload },
  name: "technical_report_dialog_get",
  props: ["document", "show", "mode"],
  data() {
    return {
      confirmDialog: {
        show: false,
        title: "",
        message: "",
        code: "",
      },
      file: {},
    };
  },
  created() {},
  watch: {
    document: function (value) {
      let idAndName = value.link.split(";");
      this.file = {
        id: idAndName[0],
        fileName: idAndName[1],
      };
      // console.log("FILE created", this.file);
    },
  },
  methods: {
    emitCancel() {
      this.$emit("whenEmitCancel");
    },
    emitUpdatePDF() {
      this.$emit("whenEmitUpdatePDF", this.document.id);
    },
    obtainPDF() {
      console.log(this.$refs["downloadFile"]);
      this.$refs["downloadFile"].getAsyncDownloadFile();
    },
    emitEvaluation(item, order) {
      this.$emit("whenEmitEvaluation", item, order);
    },
    startUpdateFile() {
      this.confirmDialog.code = "CHECK-CODE";
      this.confirmDialog.title = "Confirme o código";
      this.confirmDialog.message =
        "Por favor, envie o arquivo apenas se ele tiver o código " +
        this.document.code +
        " na capa. Deseja continuar?";
      this.confirmDialog.show = true;
    },
    finishUpdateFile() {
      let file = this.$refs["inputFile"].files[0];
      this.$emit("whenEmitUpdatePDF", this.document.id, file);
    },
    startEvaluation(evaluation) {
      this.confirmDialog.code = evaluation;
      if (evaluation === "ACCEPT") {
        this.confirmDialog.title = "Confirme aceitar";
        this.confirmDialog.message = "Deseja aceitar esse trabalho?";
      }
      if (evaluation === "REJECT") {
        this.confirmDialog.title = "Confirme rejeitar";
        this.confirmDialog.message = "Deseja rejeitar esse trabalho?";
      }
      console.log("OPEN DIALOG", evaluation);
      this.confirmDialog.show = true;
    },
    executeConfirm(code) {
      this.confirmDialog.show = false;
      if (code === "ACCEPT" || code === "REJECT") {
        this.$emit("whenEmitEvaluation", this.document, code);
      }
      if (code === "CHECK-CODE") {
        this.$refs["inputFile"].click();
      }
    },
  },
};
</script>
