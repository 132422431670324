<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              {{ message }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="emitAccept">
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
            >done</i
          >
        </v-btn>
        <v-btn color="blue darken-1" text @click="emitCancel">
          <i
            aria-hidden="true"
            class="v-icon notranslate material-icons theme--light"
          >
            close
          </i>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "custom_confirm_dialog",
  props: ["title", "message", "show", "code"],
  methods: {
    emitAccept() {
      this.$emit("whenEmitAccept", this.code);
    },
    emitCancel() {
      this.$emit("whenEmitCancel");
    }
  },
};
</script>
