<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h3>Relatórios Técnicos {{ test }}</h3>
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <technical-report-table
          :tableData="tableData"
          @whenClickOnItem="whenClickOnTableItem"
        >
        </technical-report-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <technical-report-dialog-get
          :document="dialogGet.document"
          :show="dialogGet.show"
          :mode="'EDITOR'"
          @whenEmitCancel="dialogGet.show = false"
          @whenEmitObtainPDF="whenClickOnObtainPDF"
          @whenEmitEvaluation="whenClickOnAcceptOrReject"
        >
        </technical-report-dialog-get>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-bind:headers="headers"
          v-bind:items="items"
          hide-default-footer
          no-data-text="Não há dados disponíveis para a consulta"
        >
          <template v-slot:item="row">
            <tr @click="selectItem(row.item)">
              <td class="text-xs-left">{{ row.item.title }}</td>
              <td class="text-xs-left">{{ row.item.code }}</td>
              <td class="text-xs-left">{{ row.item.type }}</td>
              <td class="text-xs-left">{{ row.item.state }}</td>
              <td class="text-xs-left">{{ row.item.comment }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog.isShowing" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialog.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row> {{ dialog.message }}</v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="acceptDialog"> OK </v-btn>
            <v-btn color="blue darken-1" text @click="cancelDialog">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar.isShowing" :timeout="5000">
        {{ snackbar.text }} {{ snackbarCode }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar.isShowing = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="reportDetail.isShowing"
        v-if="reportDetail"
        persistent
        max-width="600px"
      >
        <document-detail-dialog
          :object="reportDetail.object"
          @whenEmitOk="reportDetail.isShowing = false"
          @whenEmitAction="executeAction"
        ></document-detail-dialog>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";

import TechnicalReportTable from "./../../student/technical_report/TechnicalReportTable.vue";
import TechnicalReportDialogGet from "./../../student/technical_report/TechnicalReportDialogGet.vue";

export default {
  components: {
    TechnicalReportTable,
    TechnicalReportDialogGet,
  },
  name: "FacultyEditor",
  data() {
    return {
      tableData: [],
      dialogGet: {
        show: false,
        document: null,
      },
      test: {},
      reportDetail: {
        isShowing: false,
        object: null,
      },
      academicCode: "",
      academicLifeId: "",
      snackbarCode: "CODE",
      snackbar: {
        code: "-",
        text: "Você abriu um arquivo, lembre-se que o pdf deve conter o codigo ",
        isShowing: false,
      },
      dialog: {
        itemId: 0,
        isShowing: false,
        title: "-",
        message: "-",
        order: "-",
      },
      headers: [
        {
          sortable: false,
          text: "Titulo",
          value: "title",
          align: "left",
        },
        {
          sortable: false,
          text: "Codigo",
          value: "code",
          align: "left",
        },
        {
          sortable: false,
          text: "Tipo",
          value: "type",
          align: "left",
        },
        {
          sortable: false,
          text: "Estado",
          value: "state",
          align: "left",
        },
        { text: "Comentarios", value: "comment", sortable: false },
      ],
      items: [
        {
          id: 23,
          title:
            "On the relationship between software architecture and delivery capability.",
          code: "IC-21-23",
          type: "RT",
          note: "In english, 23 pages",
          link: "-",
          state: "Submetido",
          authors: "Autor1, Autor2, Autor3",
          abstract: "Abstract de ejemplo $$ a = 4 - 34 x$$",
          comment: "Submetido",
        },
        {
          id: 24,
          title:
            "On the relationship between software architecture and delivery capability.",
          code: "IC-21-24",
          type: "RT",
          note: "In english, 23 pages",
          link: "-",
          state: "Submetido",
          authors: "Autor1, Autor2, Autor3",
          abstract: "Abstract de ejemplo $$ a = 4 - 34 x$$",
          comment: "Submetido",
        },
        {
          id: 25,
          title:
            "On the relationship between software architecture and delivery capability.",
          code: "IC-21-25",
          type: "RT",
          note: "In english, 23 pages",
          link: "-",
          state: "Submetido",
          authors: "Autor1, Autor2, Autor3",
          abstract: "Abstract de ejemplo $$ a = 4 - 34 x$$",
          comment: "Submetido",
        },
      ],
    };
  },
  created() {
    this.getAsyncSubmittedDocuments();
  },
  methods: {
    ...mapActions("facultyEditor", [
      "ActionGetSubmittedDocuments",
      "ActionEvaluateDocument",
      "ActionGetAllProfessors",
    ]),

    async getAsyncSubmittedDocuments() {
      try {
        await this.ActionGetSubmittedDocuments();
      } catch (err) {
        console.log(err);
      } finally {
        console.log("finally");
      }
    },

    async evaluateDocument(objeto) {
      try {
        await this.ActionEvaluateDocument(objeto);
      } catch (err) {
        console.log(err);
      } finally {
        this.ActionGetSubmittedDocuments();
        console.log("finally");
      }
    },

    async getAsyncSpecificDocument(object) {
      try {
        await this.ActionGetSpecificDocument(object);
      } catch (err) {
        console.log(err);
      } finally {
        // this.ActionGetSubmittedDocuments();
        console.log("finally");
      }
    },

    clickToObtainFile(id) {
      let item = this.items.find((o) => o.id == id);
      item.state = "Em revisão";
      this.snackbarCode = item.code;
      this.snackbar.isShowing = true;
    },
    clickToAcceptDocument(id) {
      this.dialog.itemId = id;
      this.dialog.isShowing = true;
      this.dialog.order = "ACCEPT";
      this.dialog.message = "Está seguro de aceitar o documento revisado?";
      this.dialog.title = "Confirmar aceitação";
    },
    clickToRejectDocument(id) {
      this.dialog.itemId = id;
      this.dialog.isShowing = true;
      this.dialog.order = "REJECT";
      this.dialog.message = "Está seguro de rejeitar o documento revisado?";
      this.dialog.title = "Confirmar rejeição";
    },
    executeOrderDialog() {},
    acceptDialog() {
      this.dialog.isShowing = false;
      let item = this.items.find((o) => o.id == this.dialog.itemId);
      if (this.dialog.order === "REJECT") {
        item.state = "Rejeitado";
      } else {
        item.state = "Aceitado";
      }
    },
    cancelDialog() {
      this.dialog.isShowing = false;
    },
    selectItem(item) {
      this.reportDetail.object = item;
      this.reportDetail.isShowing = true;
      console.log("asdsad", item);
    },
    executeAction(id, action, comment) {
      console.log("recevido");
      this.reportDetail.isShowing = false;
      let item = this.items.find((o) => o.id == id);
      if (action === "aceitar") {
        item.state = "Aceitado";
        item.comment = "Aceitado";
      }
      if (action === "rejeitar") {
        item.state = "Rejeitado";
        item.comment = comment;
      }
    },
    whenClickOnTableItem(item) {
      this.dialogGet.document = item;
      this.dialogGet.show = true;
    },

    whenClickOnObtainPDF(id) {
      let file = this.dialogGet.document.link;
      this.getAsyncSpecificDocument({ documentId: file, body: {} });
      console.log(id);
    },
    whenClickOnAcceptOrReject(item, code) {
      this.dialogGet.show = false;
      console.log("click on accept or reject - FACULTY", item, code);
      this.evaluateDocument({ pk: item.id, body: { type: code } });
    },
  },
  computed: {
    ...mapState("facultyEditor", ["facultyEditor", "submittedDocuments"]),
  },
  watch: {
    submittedDocuments(value) {
      this.tableData = value;
      this.tableData.map(unit => {
        unit.abstract = unit.abstract_;
      });
      console.log("watch new value", value);
    },
  },
};
</script>
