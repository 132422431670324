var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._v(_vm._s(_vm.title))])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(" " + _vm._s(_vm.message) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.emitAccept }
                },
                [
                  _c(
                    "i",
                    {
                      staticClass:
                        "v-icon notranslate material-icons theme--light",
                      attrs: { "aria-hidden": "true" }
                    },
                    [_vm._v("done")]
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.emitCancel }
                },
                [
                  _c(
                    "i",
                    {
                      staticClass:
                        "v-icon notranslate material-icons theme--light",
                      attrs: { "aria-hidden": "true" }
                    },
                    [_vm._v(" close ")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }