var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                _vm._s(_vm.document.code) + " - " + _vm._s(_vm.document.title)
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("file-download", {
                            ref: "downloadFile",
                            staticStyle: { display: "none" },
                            attrs: { file: _vm.file }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(" " + _vm._s(_vm.document.title) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(" " + _vm._s(_vm.document.author) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(" " + _vm._s(_vm.document.abstract) + " ")
                      ]),
                      _c("input", {
                        ref: "inputFile",
                        staticStyle: { display: "none" },
                        attrs: { type: "file" },
                        on: { change: _vm.finishUpdateFile }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("vue-mathjax", {
                            attrs: { formula: _vm.document.abstract }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.document.link != "-"
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.obtainPDF }
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "v-icon notranslate material-icons theme--light",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v("picture_as_pdf")]
                      ),
                      _c(
                        "i",
                        {
                          staticClass:
                            "v-icon notranslate material-icons theme--light",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v(" file_download ")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm.mode === "STUDENT" &&
              (_vm.document.state === "Registrado" ||
                _vm.document.state === "Submetido" ||
                _vm.document.state === "Rejeitado")
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: { click: _vm.startUpdateFile }
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "v-icon notranslate material-icons theme--light",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v(" picture_as_pdf ")]
                      ),
                      _c(
                        "i",
                        {
                          staticClass:
                            "v-icon notranslate material-icons theme--light",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v(" file_upload ")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm.mode === "EDITOR"
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.startEvaluation("ACCEPT")
                        }
                      }
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "v-icon notranslate material-icons theme--light",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v("thumb_up")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm.mode === "EDITOR"
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.startEvaluation("REJECT")
                        }
                      }
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass:
                            "v-icon notranslate material-icons theme--light",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v("thumb_down")]
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.emitCancel }
                },
                [
                  _c(
                    "i",
                    {
                      staticClass:
                        "v-icon notranslate material-icons theme--light",
                      attrs: { "aria-hidden": "true" }
                    },
                    [_vm._v("close")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("custom-confirm-dialog", {
        attrs: {
          show: _vm.confirmDialog.show,
          message: _vm.confirmDialog.message,
          title: _vm.confirmDialog.title,
          code: _vm.confirmDialog.code
        },
        on: {
          whenEmitAccept: _vm.executeConfirm,
          whenEmitCancel: function($event) {
            _vm.confirmDialog.show = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }