var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.tableData,
      search: _vm.search,
      "hide-default-footer": "",
      "no-data-text": "Não há dados disponíveis para a consulta"
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(row) {
          return [
            _c(
              "tr",
              {
                on: {
                  click: function($event) {
                    return _vm.clickOnItem(row.item)
                  }
                }
              },
              [
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(row.item.title))
                ]),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(row.item.code))
                ]),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(row.item.type))
                ]),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(row.item.state))
                ]),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(row.item.comment))
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }